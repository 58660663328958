import { default as calculatemT3F8Yu4bnMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/calculatietool/pages/calculate.vue?macro=true";
import { default as index8XJwRaJchJMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/calculatietool/pages/index.vue?macro=true";
export default [
  {
    name: calculatemT3F8Yu4bnMeta?.name ?? "calculate",
    path: calculatemT3F8Yu4bnMeta?.path ?? "/calculate",
    meta: calculatemT3F8Yu4bnMeta || {},
    alias: calculatemT3F8Yu4bnMeta?.alias || [],
    redirect: calculatemT3F8Yu4bnMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/calculatietool/pages/calculate.vue").then(m => m.default || m)
  },
  {
    name: index8XJwRaJchJMeta?.name ?? "index",
    path: index8XJwRaJchJMeta?.path ?? "/",
    meta: index8XJwRaJchJMeta || {},
    alias: index8XJwRaJchJMeta?.alias || [],
    redirect: index8XJwRaJchJMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/calculatietool/pages/index.vue").then(m => m.default || m)
  }
]
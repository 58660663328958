import revive_payload_client_3zV9IeYHqs from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_It6yJ6xto1 from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jm6pSgg1bT from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_wwxlMITK4w from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.3_@vue+compiler-core@3.4.27_nuxt@3.11.2_postcss@8._uercupxazixjypdpou3rlkqttq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_fZBi9C8etW from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Q7U95hQ9dM from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/calculatietool/.nuxt/components.plugin.mjs";
import prefetch_client_TRZT4A3JpT from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_X0MQE3AJIe from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.18.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_Q8eOuG3AfS from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/calculatietool/.nuxt/formkitPlugin.mjs";
import plugin_20QLw4Gi6b from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.0.1_react-dom@18.3.1_react@18.3.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.mjs";
import plugin_fu7N7ojRtC from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_rollup@4.18.0_vue@3.4.27/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_CUNdNSvTxW from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errors_ZiCZB9uELj from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_V62u84E22a from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_zc8563Bk8j from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import i18n_DegFSxvh3m from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/core/plugins/i18n.ts";
import notifications_sUFtzNS9eT from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/core/plugins/notifications.ts";
export default [
  revive_payload_client_3zV9IeYHqs,
  unhead_It6yJ6xto1,
  router_jm6pSgg1bT,
  _0_siteConfig_wwxlMITK4w,
  payload_client_fZBi9C8etW,
  check_outdated_build_client_Q7U95hQ9dM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TRZT4A3JpT,
  plugin_X0MQE3AJIe,
  i18n_Q8eOuG3AfS,
  formkitPlugin_pZqjah0RUG,
  plugin_20QLw4Gi6b,
  plugin_fu7N7ojRtC,
  chunk_reload_client_CUNdNSvTxW,
  errors_ZiCZB9uELj,
  i18n_V62u84E22a,
  apollo_zc8563Bk8j,
  i18n_DegFSxvh3m,
  notifications_sUFtzNS9eT
]
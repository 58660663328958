
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "en"
]

export const localeLoaders = {
  "nl": [{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json" /* webpackChunkName: "locale__data_www_internet_jaarbeurs_nl_jaarbeurs_releases_110_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_0_2__64nuxt_43devtools_641_3_3__64unhead_43shared_641_9_12__64vue_43compiler_cor_pecyofjme6cyfh6gxgjec7szp4_node_modules__64bttr_devs_nuxt_layer_base_locales_nl_json" */), cache: true },
{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json" /* webpackChunkName: "locale__data_www_internet_jaarbeurs_nl_jaarbeurs_releases_110_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_0_2__64nuxt_43devtools_641_3_3__64unhead_43shared_641_9_12__64vue_43compiler_co_clox342lea74mcr4od5jcv2eeu_node_modules__64bttr_devs_nuxt_layer_sofie_locales_nl_json" */), cache: true },
{ key: "../../core/locales/nl.json", load: () => import("../../core/locales/nl.json" /* webpackChunkName: "locale__data_www_internet_jaarbeurs_nl_jaarbeurs_releases_110_nuxt_core_locales_nl_json" */), cache: true }],
  "en": [{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json" /* webpackChunkName: "locale__data_www_internet_jaarbeurs_nl_jaarbeurs_releases_110_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_0_2__64nuxt_43devtools_641_3_3__64unhead_43shared_641_9_12__64vue_43compiler_cor_pecyofjme6cyfh6gxgjec7szp4_node_modules__64bttr_devs_nuxt_layer_base_locales_en_json" */), cache: true },
{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json" /* webpackChunkName: "locale__data_www_internet_jaarbeurs_nl_jaarbeurs_releases_110_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_0_2__64nuxt_43devtools_641_3_3__64unhead_43shared_641_9_12__64vue_43compiler_co_clox342lea74mcr4od5jcv2eeu_node_modules__64bttr_devs_nuxt_layer_sofie_locales_en_json" */), cache: true },
{ key: "../../core/locales/en.json", load: () => import("../../core/locales/en.json" /* webpackChunkName: "locale__data_www_internet_jaarbeurs_nl_jaarbeurs_releases_110_nuxt_core_locales_en_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "nl",
      "files": [
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json",
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json",
        "../core/locales/nl.json"
      ]
    },
    {
      "code": "en",
      "files": [
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json",
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json",
        "../core/locales/en.json"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "../core/locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nl",
    "files": [
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json"
      },
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json"
      },
      {
        "path": "../core/locales/nl.json"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json"
      },
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json"
      },
      {
        "path": "../core/locales/en.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
